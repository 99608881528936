import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    "incidentFormContainer",
    "incidentFormTemplate",
    "actionLink",
    "secondaryIncidentFormTemplate",
    "secondaryActionLink",
    "hideable",
    "description",
    "category",
    "causeDetail",
    "cause",
    "responsible",
    "cat",
    "amount",
    "contact",
    "credit",
    "is_credit",
  ];
  
  connect() {
    this.hideableTargets.forEach(el => {
      el.hidden = true;
    });
  }

  toggleForm(e) {
    e.preventDefault();
    if (this.isFormVisible) {
      this.hideForm(e.target.id);
    } else {
      this.showForm(e.target.innerHTML);
    }
  }

  showForm(type) {
    if (type == 'Reembolso parcial') {
      this.element.insertAdjacentHTML(
        "beforeend",
        this.secondaryIncidentFormTemplateTarget.innerHTML
      );
      this.isFormVisible = true;
      this.secondaryActionLinkTarget.innerHTML = "Cancelar";
    } else {
      this.element.insertAdjacentHTML(
        "beforeend",
        this.incidentFormTemplateTarget.innerHTML
      );
      this.isFormVisible = true;
      this.actionLinkTarget.innerHTML = "Cancelar";
    }
  }

  hideForm(type) {
    if (type == "partial") {
      this.element.removeChild(this.element.lastElementChild);
      this.secondaryActionLinkTarget.innerHTML =
        `<strong>Reembolso parcial</strong>`;
      this.isFormVisible = false;
    } else {
      this.element.removeChild(this.element.lastElementChild);
      this.actionLinkTarget.innerHTML =
        `<strong>${this.actionLinkTarget.dataset.incidentsText}</strong>`;
      this.isFormVisible = false;
    }
  }

  set isFormVisible(value) {
    this.data.set("isFormVisible", value);
  }

  get isFormVisible() {
    return this.data.get("isFormVisible") === "true";
  }

  enablePayment({ target }) {
    this.enableField(target, "amount");
  }

  enableField(target, element) {
    if (target.value && target.value.length !== 0) {
      this.hideableTargets.forEach(el => {
        if (el.id === element) el.hidden = false
      });
    } else {
      this.hideableTargets.forEach(el => {
        if (el.id === element) el.hidden = true
      });
    }
  }

  enableCredit(target, conditionStr) {
    if (target.value === conditionStr) {
      this.hideableTargets.find(el => (el.id === 'credit')).hidden = false;
      this.hideableTargets.find(el => (el.id === 'is_credit')).hidden = false;
    } else {
      this.hideableTargets.find(el => (el.id === 'credit')).hidden = true;
      this.hideableTargets.find(el => (el.id === 'is_credit')).hidden = true;
    }
  }

  manualPurchase(target) {
    return target.value === "manual_purchase";
  }

  updateCategoryIncidentForm({ target }) {
    const { value, dataset } = target;
    const {
      categories,
      categoriesTranslation,
      causes,
      causesTranslation,
    } = dataset;
    this.enableField(target, "description");
    this.updateDropdown(value, categories, categoriesTranslation,
      this.descriptionTarget);
    this.enableField(target, "cause");
    this.updateDropdown(value, causes, causesTranslation, this.causeDetailTarget);
    //this.clearResult(this.responsibleTarget);
    this.updateResponsibleSelect({ target });
    this.updateContactSelect() //only for manual purchases
    this.updateReservamosAmountField(); //only for manual purchases
    this.enableCredit(target, "credit");
  }

  updateResponsibleSelect({ target }) {
    const {
      dataset: { responsibles, responsiblesTranslation, refund },
    } = target;
    const value = refund ? "refund" : this.causeDetailTarget.value;
    this.updateDropdown(this.causeDetailTarget.value, responsibles,
      responsiblesTranslation, this.responsibleTarget, true);
  }

  updateContactSelect() {
    if (this.manualPurchase(this.catTarget)) {
      const manual_option = "no_contact";
      this.contactTarget.childNodes.forEach((o) => {
        if (o.value === manual_option) {
          this.contactTarget.selectedIndex = o.index;
        }
      });
    } else {
      this.contactTarget.selectedIndex = 0;
    }
  }

  updateReservamosAmountField() {
    if (this.manualPurchase(this.catTarget) && this.descriptionTarget.value === "crawler") {
      this.amountTarget.value = 0;
    } else {
      this.amountTarget.value = this.amountTarget.defaultValue;
    }
  }

  updateCauseSelect(){
    this.updateReservamosAmountField();
    this.enableCredit(this.descriptionTarget, "opening_credit");
    if (this.manualPurchase(this.catTarget) && this.descriptionTarget.value === "corporate") {
      const option = "other_services";
      this.causeDetailTarget.childNodes.forEach((o) => {
        if (o.value === option) {
          this.causeDetailTarget.selectedIndex = o.index;
        }
      });
    }
  }

  updateDropdown(value, options, translations, element, responsible = false) {
    this.clearResult(element);
    const info = JSON.parse(options);
    const descriptions = JSON.parse(translations);
    if (value && responsible && info) {
      const select_options = Object.entries(info).filter(o => o[1].includes(value));
      const elements = select_options.map(o => o[0]);
      elements.forEach((e) => {
        const option = document.createElement("option");
        option.value = e;
        option.innerHTML = descriptions[e];
        element.appendChild(option);
      });
    } else {
      const elements = info[value];
      elements.forEach((e) => {
        const option = document.createElement("option");
        option.value = e || '-' ;
        option.innerHTML = descriptions[e] || '-';
        element.appendChild(option);
      });
    }
  }

  clearResult(element) {
    element.innerHTML = "";
  }

}