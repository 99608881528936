$(document).on('turbolinks:load', () => {
  $originInput = $('*[data-behavior="originAutocomplete"]')
  $destinationInput = $('*[data-behavior="destinationAutocomplete"]')
  $transporterInput = $('*[data-behavior="transporterRouteAutocomplete"]')

  const categories = [
    {
      listLocation: "codes",
      maxNumberOfElements: 30,
      header: "C ó d i g o s :"
    },
    {
      listLocation: "terminals",
      maxNumberOfElements: 30,
      header: "T e r m i n a l e s :"
    },
    {
      listLocation: "cities",
      maxNumberOfElements: 30,
      header: "C i u d a d e s :"
    }
  ];

  const template = {
    type: "description",
    fields: {
      description: "transporter"
    }
  };
  
  const cats = ["tt", "terminal", "city"];

  var origins = {
    url: function(phrase) {
      return "/routes/search?place=" + phrase;
    },
    categories,
    getValue: function(element) {
      return element.name;
    },
    template,
    list: {
      maxNumberOfElements: 99,
      match: {
        enabled: true
      },
      sort: {
        enabled: true
      },
      onSelectItemEvent: function () {
        const valueCat = $("#admin_route_search_origin").getSelectedItemData().category;
        const valueId = $("#admin_route_search_origin").getSelectedItemData().id;
        $(`#admin_route_search_origin_${valueCat}`).val(valueId).trigger("change");

        const nullCats = cats.filter(c => c !== valueCat);
        nullCats.forEach(c => $(`#admin_route_search_origin_${c}`).val(null).trigger("change"));
      },
    },
  };

  var destinations = {
    url: function(phrase) {
      return "/routes/search?place=" + phrase;
    },
    categories,
    getValue: function(element) {
      return element.name;
    },
    template,
    list: {
      maxNumberOfElements: 99,
      match: {
        enabled: true
      },
      sort: {
        enabled: true
      },
      onSelectItemEvent: function() {
        const valueCat = $("#admin_route_search_destination").getSelectedItemData().category;
        const valueId = $("#admin_route_search_destination").getSelectedItemData().id;
        $(`#admin_route_search_destination_${valueCat}`).val(valueId).trigger("change");

        const nullCats = cats.filter(c => c !== valueCat);
        nullCats.forEach(c => $(`#admin_route_search_destination_${c}`).val(null).trigger("change"));
      },
    },
  };

  var transporters = {
    url: function(phrase) {
      return "/transporters/search?q=" + phrase;
    },
    getValue: "abbr",
    template: {
      type: "description",
      fields: {
        description: "name"
      }
    },
  };

  // $originInput.easyAutocomplete(origins);
  // $destinationInput.easyAutocomplete(destinations);
  $transporterInput.easyAutocomplete(transporters);

  $("#admin_route_search_origin").change(function (event) {
    $("#admin_route_search_origin_terminal").val(null).trigger("change");
    $("#admin_route_search_origin_tt").val(null).trigger("change");
    $("#admin_route_search_origin_city").val(null).trigger("change");
  });

  $("#admin_route_search_destination").change(function (event) {
    $("#admin_route_search_destination_terminal").val(null).trigger("change");
    $("#admin_route_search_destination_tt").val(null).trigger("change");
    $("#admin_route_search_destination_city").val(null).trigger("change");
  });

});